import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Jobs from './Pages/Jobs';
import Dashboard from './Pages/Dashboard';
import Login from './Components/Authentication/Login';
import Signup from './Components/Authentication/SignUp';
import PrivateRoute from './Components/Admin/PrivateRoute';
import Activate from './Components/Authentication/Activate';
import Logout from './Components/Authentication/Logout';
import ForgotPassword from './Components/Authentication/ForgotPassword';
import ResetPassword from './Components/Authentication/ResetPassword';
import { LoadingProvider } from './Components/Contexts/LoadingContext';
import Spinner from './Components/Loading/Spinner';
import Layout from './Components/Structure/Layout';
import NotFound from './Components/NotFound';
import { AuthProvider } from './Components/Contexts/AuthContext';
import './styles/main.css';
import CreateAdmin from './Components/Admin/CreateAdmin';
import AdminDashboard from './Components/Admin/AdminDashboard';
import UserDashboard from './Components/User/UserDashboard';
import ViewUsers from './Components/Admin/ViewUsers';
import UserProfileView from './Components/Admin/UserProfileView';
import PostJob from './Components/Admin/PostJob';
import JobDetails from './Components/Jobs/JobDetails';
import ApplyForm from './Components/Jobs/ApplyForm';
import ViewApplications from './Components/Admin/ViewApplications';
import ViewPostedJobs from './Components/Admin/ViewPostedJobs';

function App() {
  return (
    <LoadingProvider>
      <AuthProvider>
    <Router>
      <Layout>
      <div className="App">
        <Spinner />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/activate/:token" element={<Activate />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/unauthorized" element={<NotFound />} />
          <Route path='/jobs/:jobId' element={<JobDetails />} />
          {/* Protected Routes */}
          <Route element={<PrivateRoute roles={['user', 'admin']} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/logout' element={<Logout />} />
          </Route>
          {/* Admin Protected Routes */}
          <Route element={<PrivateRoute roles={['admin']} />}>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path='/admin/create-admin' element={<CreateAdmin />}/>
            <Route path='/admin/view-users' element={<ViewUsers />}/>
            <Route path='/admin/view-users/:userId' element={<UserProfileView />} />
            <Route path='/admin/create-job' element={<PostJob />} />
            <Route path='/admin/job-applications' element={<ViewPostedJobs />} />
            <Route path='/admin/job-applications/:jobId' element={<ViewApplications />} />
          </Route>
          {/* User Protected Routes */}
          <Route element={<PrivateRoute roles={['user']} />}>
            <Route path="/user" element={<UserDashboard />} />
            <Route path='/apply/:jobId' element={<ApplyForm />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      </Layout>
    </Router>
    </AuthProvider>
    </LoadingProvider>
  );
}

export default App;
